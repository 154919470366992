<template>
  <tr>
    <td>{{ question.name }}</td>
    <td>{{ questionTypes[question.type] }}</td>

    <td class="text-sm-right">
      <v-icon :color="question.isRoot ? 'green' : 'grey'">
        {{ question.isRoot ? "check" : "clear" }}
      </v-icon>
    </td>
    <td class="text-sm-right">
      <v-icon :color="question.isFilterable ? 'green' : 'grey'">
        {{ question.isFilterable ? "check" : "clear" }}
      </v-icon>
    </td>
    <td class="text-sm-right">
      <v-icon :color="question.isMultiple ? 'green' : 'grey'">
        {{ question.isMultiple ? "check" : "clear" }}
      </v-icon>
    </td>
    <td>
      <v-btn icon :href="url">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import QuestionMixin from "./../mixins/QuestionMixin";

export default {
  name: "question-table-row",
  mixins: [QuestionMixin],
  props: {
    questionId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      questionTypes: window.config.enums.collection_question_types,
      url: `${process.env.VUE_APP_AUTH_SERVER}/backoffice/questions/${this.questionId}`
    };
  }
};
</script>
