<template>
  <generic-overview
    :route="route"
    :actions="actions"
    :filters="filters"
    searchable
    title="Questions"
  >
    <table-data-provider
      slot="content"
      ref="table"
      :url="url"
      :includes="includes"
      :pagination="pagination"
      :filters="filters"
      :entity-name="entityName"
      :schema="schema"
    >
      <v-data-table
        slot-scope="props"
        :headers="headers"
        :items="props.items"
        :pagination.sync="pagination"
        :total-items="props.totalItems"
        :rows-per-page-items="[20, 50, 100]"
        disable-initial-sort
        disable-page-reset
        :loading="props.isLoading"
        class="elevation-1"
      >
        <question-table-row
          slot="items"
          slot-scope="{ item }"
          :question-id="item.id"
        />
      </v-data-table>
    </table-data-provider>
  </generic-overview>
</template>
<script type="text/babel">
import { question as schema } from "@/store/schema";
import TableDataProvider from "@/components/legacy/generic/data/TableDataProvider";
import GenericOverview from "@/components/generic/overview/Overview";
import QuestionTableRow from "@/components/question/QuestionTableRow";

export default {
  name: "question-overview",
  components: {
    QuestionTableRow,
    TableDataProvider,
    GenericOverview
  },
  data() {
    return {
      route: "question",
      url: "api/v1/questions",
      actions: [
        //                    {icon: 'add', color: 'pink', to: {name: 'questions.create'}},
        {
          icon: "add",
          color: "pink",
          href: "/backoffice/collection/question/create"
        }
      ],
      pagination: {},
      filters: {
        search: null
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        {
          text: "Root",
          value: "is_root",
          sortable: false,
          align: "right",
          width: "100px"
        },
        {
          text: "Filterable",
          value: "is_filterable",
          align: "right",
          width: "100px"
        },
        {
          text: "Multiple",
          value: "is_multiple",
          align: "right",
          width: "100px"
        },
        {
          text: "Actions",
          align: "right",
          value: "name",
          sortable: false,
          width: "100px"
        }
      ],
      includes: [],
      entityName: "question"
    };
  },
  computed: {
    schema() {
      return schema;
    }
  }
};
</script>
