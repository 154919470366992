<script>
export default {
  name: "question-mixin",
  computed: {
    question() {
      return this.$store.getters["entities/getEntity"]({
        name: "question",
        id: this.questionId
      });
    },
    questionHasOptions() {
      return this.question.type.includes([
        "SELECT",
        "INPUT_CHECKBOX",
        "INPUT_RADIO"
      ]);
    }
  }
};
</script>
